<template>
  <div class="container">


    <div class="syllabus-literature mt-4">
      <h4 style="text-align: center">Литература силлабуса</h4>

      <div class="form-row" v-for="(syllabusLiteratureInfo, index) in syllabusLiterature_form.newSyllabusLiteratureInfos" :key="index">
        <div class="literature-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowSyllabusLiteratureInfo(index)"></i>
        </div>


        <div class="form-group row mt-4">
          <label for="title" class="col-md-3 col-form-label">Название</label>
          <div class="col-md-9">
            <input id="title" type="text" class="form-control" placeholder="Название"
                   :value="syllabusLiteratureInfo.title" @input="changeNewSyllabusLiteratureData(index,'title', $event)">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="author" class="col-md-3 col-form-label">Автор</label>
          <div class="col-md-9">
            <input id="author" type="text" class="form-control" placeholder="Автор"
                   :value="syllabusLiteratureInfo.author" @input="changeNewSyllabusLiteratureData(index,'author', $event)">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="publishing_year" class="col-md-3 col-form-label">Год выпуска</label>
          <div class="col-md-9">
            <input id="publishing_year" type="number" class="form-control" placeholder="Год выпуска"
                   :value="syllabusLiteratureInfo.publishing_year" @input="changeNewSyllabusLiteratureData(index,'publishing_year', $event)">
          </div>
        </div>


        <div class="form-group row mt-4">
          <label for="literature_type" class="col-md-3 col-form-label">Вид литературы</label>
          <div class="col-md-9">
            <select class="form-control form-select" name="literature_type" id="literature_type"
                    @input="changeNewSyllabusLiteratureData(index,'literature_type', $event)">
              <option :selected="syllabusLiteratureInfo.literature_type == ''">Выберите вид литературы</option>
              <option value="1" :selected="syllabusLiteratureInfo.literature_type == 1">Базовая</option>
              <option value="2" :selected="syllabusLiteratureInfo.literature_type == 2">Дополнительная</option>
            </select>
          </div>
        </div>


        <hr>


      </div>
      <div class="form-group">
        <button @click="addRowSyllabusLiteratureInfo" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-pill text-white btn-block btn-primary" @click="submitSyllabusLiterature">Сохранить
          </button>
        </div>
      </div>



    </div>


  </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SyllabusLiteratureOld",
  data() {
    return {
      syllabus_id: +this.$route.query.syllabus_id || 0,
    }
  },
  computed: {
    ...mapState('syllabusLiteratureOld', ['syllabusLiterature_form']),
  },

  methods: {
    ...mapActions('syllabusLiteratureOld', ['GET_SYLLABUS_LITERATURE_DATA','POST_SYLLABUS_LITERATURE_DATA',
      'PUT_SYLLABUS_LITERATURE_DATA','DELETE_SYLLABUS_LITERATURE_DATA']),
    ...mapMutations('syllabusLiteratureOld', ['ADD_ROW_SYLLABUS_LITERATURE_INFO','DELETE_ROW_SYLLABUS_LITERATURE_INFO', 'SET_NEW_SYLLABUS_LITERATURE_DATA']),
    addRowSyllabusLiteratureInfo() {
      this.ADD_ROW_SYLLABUS_LITERATURE_INFO(this.syllabus_id)
    },
    deleteRowSyllabusLiteratureInfo(index) {
      if (this.syllabusLiterature_form.newSyllabusLiteratureInfos[index].id) {
        this.DELETE_SYLLABUS_LITERATURE_DATA(index);
      }
      this.DELETE_ROW_SYLLABUS_LITERATURE_INFO(index)
    },

    changeNewSyllabusLiteratureData(index, property, e, val = 'value') {
      let value = e.target[val]
      if (property == 'publishing_year' ){
        value = +value
      }
      console.log(value, "value")
      this.SET_NEW_SYLLABUS_LITERATURE_DATA({index, property, value})
    },

    submitSyllabusLiterature() {

      const postSyllabusLiterature = this.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.id == undefined)
      console.log(postSyllabusLiterature, 'postSyllabusLiterature')

      if (postSyllabusLiterature) {
        this.POST_SYLLABUS_LITERATURE_DATA().then(res => {
          if (res.success == true) {
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
            this.GET_SYLLABUS_LITERATURE_DATA(this.syllabus_id)
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Добавление', text: errorText})
          }
        })
      }

      const putSyllabusLiterature = this.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.id != undefined)
      console.log(putSyllabusLiterature, 'putSyllabusLiterature')
      if (putSyllabusLiterature) {
        this.PUT_SYLLABUS_LITERATURE_DATA().then(res => {
          if (res.success == true) {
            this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
            this.GET_SYLLABUS_LITERATURE_DATA(this.syllabus_id)
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Обновление', text: errorText})
          }
        })
      }



    }

  },
  async mounted() {
    this.GET_SYLLABUS_LITERATURE_DATA(this.syllabus_id)
  }

}
</script>

<style scoped>

</style>